import * as THREE from 'three';
export function addGradient(mesh) {
  let conduit = mesh.children.find(child => child.name === 'conduit')
  conduit.geometry.computeBoundingBox();

  let shadertestmaterial = new THREE.ShaderMaterial({
  uniforms: {
    color1: {
      value: new THREE.Color('#bff1ff')
    },
    color2: {
      value: new THREE.Color('#d8fdfe')
    },
    bboxMin: {
      value: conduit.geometry.boundingBox.min
    },
    bboxMax: {
      value: conduit.geometry.boundingBox.max
    }
  },
  vertexShader: `
    uniform vec3 bboxMin;
    uniform vec3 bboxMax;

    varying vec2 vUv;

    void main() {
      vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
  `,
  fragmentShader: `
    uniform vec3 color1;
    uniform vec3 color2;

    varying vec2 vUv;

    void main() {
      gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
    }
  `
  });
  

  conduit.material = shadertestmaterial;
  conduit.visible = false;
}

import './style.css';

import gsap from 'gsap';
//import Stats from 'stats.js';
import $ from "jquery";
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { TTFLoader } from 'three/examples/jsm/loaders/TTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { Constants } from './constants.js';
import { createBackground } from 'three-vignette-background-esm/dist/noise-background';
import { getOfficeFromObject } from './functions/getOfficeFromObject.js';
import { addGradient } from './functions/addGradient.js';
import { createShareURL } from './functions/createShareURL.js';

// BLOOM
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
//END BLOOM
import logo from './SOLIDWORKS-white.png';

console.log(logo)



let skipIntro = true;


//var stats = new Stats();
//stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
//document.body.appendChild( stats.dom );

// Canvas
const canvas = document.querySelector('canvas.webgl');
const scene = new THREE.Scene();
let camera;
const clock = new THREE.Clock();

let background = new createBackground({grainScale: 0.001, colors: [ '#008ad4','#225179' ]})
scene.add(background)
let labelpos = [{'x':0,'y':0}];
let mode = "begin";
let prevPoint = new THREE.Vector3(0, 0, 0)
let topfloor = 3;
let sign;
let roomZoom = false;
let loadTime;



let config = {
  roomOrder: "",
  name: "",
  com: "",
  skin: ""
};

let shareURL = "";

let GradientUpdater = 0;

// HERE IS HOW WE WILL FORM OUR REQUESTS FOR A CUSTOM BUILDING
// WE NEED TO HAVE AN IF THE BUILDING IS IN A PARAM NO BUILDING
// AND A CREATE YOUR OWN BUILDING

// https://solidworks.vercel.app?order=0123456789&name=bob&com=works&skin=1

const queryString = window.location.search;
console.log(queryString);
const urlParams = new URLSearchParams(queryString);
const PresetOrder = urlParams.get('order')
//const PresetName = urlParams.get('name')
//const PresetCom = urlParams.get('com')
const loadingManager = new THREE.LoadingManager(
  //loaded
  () => {
   
    //gsap.to(canvas, {duration:3, css:{opacity:1}} )
    gsap.to($("#loadingText"), {delay:.25, duration:.25, css:{left:'-200%'}} )
    gsap.to($("#loadingGif"), {delay:.5, duration:.25, css:{scale:0},onComplete:removeLoader} )


    function removeLoader(){
      $("#loadingGif").remove();
      $("#loadingText").remove();
      $('#intro').css({'opacity':'0','display':'block'})
      gsap.to($("#intro"), { duration:.5, css:{opacity:1}} )
       if (!PresetOrder && window.innerWidth > 768 ){
        $('#intro')[0].play()
        $('#intro')[0].addEventListener('ended',introHandler,false);
      }else{
        console.log("preset-video");
        hideIntro();
      }
      $('#tc-privacy-wrapper').css({'z-index': 'unset!important'}); //safari bug is hiding it.
      if (skipIntro){
        introHandler();
      }
    }
   



    


    function introHandler(e) {
      if (!PresetOrder){
      console.log("STAGE1")
      $('#stage1').css({'display':'flex'});}
      gsap.to(canvas, {duration:3, css:{opacity:1}} )
      gsap.to($('#stage1'), {duration:.5, css:{opacity:1}} )
    }
    function hideIntro(){
      $('#intro').remove();

    }
  },
  //progress
  (itemUrl, itemsLoaded, itemsTotal) =>{
    const loadingProgress = itemsLoaded/itemsTotal
    loadingBar.style.transform = `scaleX(${loadingProgress})`
  }

  );
const textureLoader = new THREE.TextureLoader(loadingManager);
const gltfLoader = new GLTFLoader(loadingManager);

let glt1;

let mixers = [];
let animators = [];

// The menu of offices
let offices = new THREE.Group();
let menu = new THREE.Group();
menu.scale.x = 2;
menu.scale.y = 2;
menu.position.x = .05;
//menu.position.y = -.5;
menu.name = "menu";

// This will hold the offices
let building = new THREE.Group();

let officeMeshes = [];
let personaMeshes = []//[null,null,null,null,null,null,null,null];
let mouseMeshes = [];
let skins = [];
let buildingSkin;
let clickedSkin = 0;

let spinning = false;

let officeMoving = false;

let officehover;

let labelhover;

let ml = document.getElementById("mainlabel");

let buildingPositionCounter = 0;

// This is the stage manager for the app.
let stage = 0;

let cloud1;
let cloud2;

let logosprite;

let controls;
let renderer;
const sizes = { width: window.innerWidth-400, height: window.innerHeight }
let pixelRatio = window.devicePixelRatio
let AA = true
let canvasHOffset = 400;


  if (pixelRatio > 1) {
    AA = false
  }
  renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: AA, powerPreference:  "high-performance" })
  
  //mobile
  if (window.innerWidth < 768){
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight/2;
    canvasHOffset = 0;
  }
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  renderer.outputEncoding = THREE.sRGBEncoding



let buildingName;
let nameMesh;

let selectedOfficeIndex;

let raycaster = new THREE.Raycaster();

// const numParticles = 10;

let line;
let points;
let curveResolution = 15;

let testmat;
let oi
let clickedOffice
let clickedPersona
let personaLabelOffset =350
let roomConnections = [];
let currentRoom;

//const buildingmaterial = new THREE.MeshBasicMaterial({color: 0x000000 })
const buildingmaterial = new THREE.MeshBasicMaterial({map:null})
const signmaterial = Constants.cubeLightMaterial;

let signMesh;
let texture1;

let videos = []

let coworkersOver = false


//BLOOM CODE


      const ENTIRE_SCENE = 0, BLOOM_SCENE = 1;

      const bloomLayer = new THREE.Layers();
      bloomLayer.set( BLOOM_SCENE );

      const params = {
        exposure: 2.9,
        bloomStrength: 6.3,
        bloomThreshold: 0,
        bloomRadius: 3,
        scene: "Scene with Glow"
      };

      const darkMaterial = new THREE.MeshBasicMaterial( { color: "black" } );
      const materials = {};

      /*const renderer = new THREE.WebGLRenderer( { antialias: true } );
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( window.innerWidth, window.innerHeight );
      renderer.toneMapping = THREE.ReinhardToneMapping;
      document.body.appendChild( renderer.domElement );*/

      //const scene = new THREE.Scene();

      scene.add( new THREE.AmbientLight( 0x404040 ) );

      const renderScene = new RenderPass( scene, camera );

      const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
      bloomPass.threshold = params.bloomThreshold;
      bloomPass.strength = params.bloomStrength;
      bloomPass.radius = params.bloomRadius;

      const bloomComposer = new EffectComposer( renderer );
      bloomComposer.renderToScreen = true;
      bloomComposer.addPass( renderScene );
      bloomComposer.addPass( bloomPass );

      const finalPass = new ShaderPass(
        new THREE.ShaderMaterial( {
          uniforms: {
            baseTexture: { value: null },
            bloomTexture: { value: bloomComposer.renderTarget2.texture }
          },
          vertexShader: document.getElementById( 'vertexshader' ).textContent,
          fragmentShader: document.getElementById( 'fragmentshader' ).textContent,
          defines: {}
        } ), "baseTexture"
      );
      finalPass.needsSwap = true;

      const finalComposer = new EffectComposer( renderer );
      finalComposer.addPass( renderScene );
      finalComposer.addPass( finalPass );


// END BLOOM CODE

// footer init //
$('meta[content="footer_privacy_center"]').parent()
            .on("click", function (e) {
                return e.preventDefault(),
                    tC.privacyCenter.showPrivacyCenter(),
                    !1

            });

// footer end //



function init() {
  
  
  
    



  const directionalLight = new THREE.DirectionalLight( 0xffffff, 2.3 );
  scene.add( directionalLight );
  
  const light = new THREE.AmbientLight( 0xff0000 ); // soft white light
  scene.add( light );
  for(let j = 0; j<Constants.text.length; j++){
      addPersona('models/'+Constants.text[j].pmodel,'textures/'+Constants.text[j].ptexture,j);
      }

      addClouds()
  // LOAD SKINS

  for (let i = 0; i < Constants.skins.length;i++){
      texture1 = textureLoader.load('textures/'+Constants.skins[i].texture, function(tex){
      buildingmaterial.map = tex

      tex.flipY = false
      tex.encoding = THREE.sRGBEncoding
      console.log("Texture1");
      $('#customize .container').append('<button class="skinButton" data-skin="'+i+'" style="background-image:url(textures/'+Constants.skins[i].thumb+');"></button>');
      console.log("Texture2");
      $('#customize .skinButton').click(function(){
      changeSkin($(this).data('skin'));

     // console.log("skin"+$(this).data('skin'))

      if ($('#customizeButton').hasClass('disabled')){
        $('#customizeButton').removeClass('disabled')
      }
      })




    });
    skins.push(texture1);

    


  }

  
  

  scene.add(building)
  //addCity();
  //renderBuilding();

  // LOAD BUILDING FROM PRESET
  if (PresetOrder) {
    //check to see if all the personas are loaded
    loadTime = setInterval(checkLoad,500);
    console.log("PresetOrder")


  }else{
     //$("#stage1").css({'display':'flex'});
  }

  const near = .25;
  const far = 70;
  const color = 0x225179;
  //scene.background = new THREE.Color(color);
  
 
  // Base camera
  camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 60)
  camera.position.x = 6
  camera.position.y = 3
  camera.position.z = 0

  scene.add(camera)
  scene.add(offices)
  camera.add(menu)
  offices.position.y = 10


  const logomap = new THREE.TextureLoader().load( logo );
  const logomaterial = new THREE.SpriteMaterial( { map: logomap,color:'0xffcc00',depthTest: false,side:THREE.DoubleSide } );

    logosprite = new THREE.Sprite( logomaterial );
    logosprite.scale.set(.278, 0.05, 1);  
    camera.add( logosprite );
    logosprite.position.set( .25, -.36,-1);
    logosprite.visible = false;
//////////////////////////////////////
//            ORBIT CONTROLS        //
//////////////////////////////////////
/*
  controls = new OrbitControls(camera, canvas)
  controls.target = new THREE.Vector3(-1, 1, .2)
  controls.enableDamping = Constants.orbit.enableDamping;
  if (Constants.orbit.lock) {
    // How far you can orbit vertically, upper and lower limits.
    // Range is 0 to Math.PI radians.
    controls.minPolarAngle = Constants.orbit.minPolarAngle; // radians
    controls.maxPolarAngle = Constants.orbit.maxPolarAngle; // radians

    // How far you can orbit horizontally, upper and lower limits.
    // If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
    controls.minAzimuthAngle = Constants.orbit.minAzimuthAngle; // radians
    controls.maxAzimuthAngle = Constants.orbit.maxAzimuthAngle; // radians

    controls.minDistance = Constants.orbit.minDistance;
    controls.maxDistance = Constants.orbit.maxDistance;
  }
*/
//////////////////////////////////////
//        END ORBIT CONTROLS        //
//////////////////////////////////////


//////////////////////////////////////
//            RENDER SETTINGS       //
//////////////////////////////////////

  // SET antialias based on whether or not we are working with a retina display
 

//post processing stuff
  //composer= new EffectComposer(renderer);
  //composer.addPass(new RenderPass(scene, camera));
  //composer.addPass(new EffectPass(camera, new BloomEffect()));



}

//////////////////////////////////////
//        END RENDER SETTINGS       //
//////////////////////////////////////



//////////////////////////////////////
//    HOVER ON MENU OR OFFICE       //
//////////////////////////////////////

window.addEventListener("mousemove", (e) => {
  let mouse = new THREE.Vector2();
  mouse.x = ( (event.clientX - canvasHOffset ) / (window.innerWidth - canvasHOffset) ) * 2 - 1;
  mouse.y = - ( event.clientY / $('#canvas').height() ) * 2 + 1;
  if(window.innerWidth < 768){
    mouse.y = - ( event.clientY / $('#canvas').height() ) * 2 +1.2;
  }
  if (mouse.x > -1 && mouse.x < 1){
  building.rotation.y = -mouse.x/10;}
  building.rotation.z = -.1

  if(mode !=="begin" && mode !=="rendering"){
  
  
  raycaster.setFromCamera( mouse, camera );
  let intersects;
  if (mode !== "final"){
    intersects = raycaster.intersectObjects( mouseMeshes, true );
  } else {
    intersects = raycaster.intersectObjects( officeMeshes, true );
  }
  if (intersects.length > 0) {
    
    coworkersOver = true;
    let hoveredOfficeMesh = intersects[0].object.parent.parent;
    let oi = hoveredOfficeMesh.userData.office_index
    
    if (currentRoom !== oi && mode == "final" && !roomZoom){
    currentRoom = oi;
    lightConnections(currentRoom);
    }

    // LETS FIX TO MAKE THIS JUST START
    animateMe(oi);
    
   
    if ((!personaMeshes[oi].userData.inBuilding) || (mode == "final" && !roomZoom)){
    document.getElementById("canvas").style.cursor = 'url("Hand.png"), pointer';
    let hpos = worldPosToScreen(hoveredOfficeMesh.persona,camera);
   // console.log(hoveredOfficeMesh.persona)
    labelpos.x = event.clientX  - ($('#mainlabel').width()/2) 
    var lpy =  hpos.y - personaLabelOffset
    if (lpy < 10){
      lpy = 10
    }
    labelpos.y = lpy

    let tt = 'translate('+labelpos.x+'px,'+labelpos.y+'px) scale(1)'

    $('#mainlabel').css({'transform':tt,'opacity':'1'})
    if (mode !== "final"){
      officehover = "<strong>"+Constants.text[oi].name+"</strong>"+"<br>"+Constants.text[oi].summary; 
    }else{
      if (Constants.text[oi].shortdesc){
      officehover = "<strong>"+Constants.text[oi].name+"</strong>"+"<br>"+Constants.text[oi].shortdesc; 
      } else {
      officehover = "<strong>"+Constants.text[oi].name+"</strong>"+"<br>"+Constants.text[oi].description;  
      }
    }   
    $('#mainlabel #labeltext').html(officehover);
    
    } 

    



  } else {

    if (currentRoom !== null){
    currentRoom = null;
    dimConnections();
    }


    document.getElementById("canvas").style.cursor = "auto";

    if(coworkersOver){
    let tt = 'translate('+labelpos.x+'px,'+labelpos.y+'px) scale(0)'
    $('#mainlabel').css({'transform':tt,'opacity':'0'})
    }
    document.getElementById("stage3").style.display = 'none';
    if(!ml.classList.contains('hide')){
      //ml.classList.add('hide');
    }
  }
}
})

//////////////////////////////////////
//   END HOVER ON MENU OR OFFICE    //
//////////////////////////////////////





//////////////////////////////////////
//    CLICK ON MENU OR OFFICE       //
//////////////////////////////////////

window.addEventListener("pointerdown", (e) => {
  if (mode =="building" || mode=="final"){

  let mouse = new THREE.Vector2();
  mouse.x = ( (event.clientX - canvasHOffset ) / (window.innerWidth - canvasHOffset) ) * 2 - 1;
  mouse.y = - ( event.clientY / $('#canvas').height() ) * 2 + 1;

  if(window.innerWidth < 768){
    mouse.y = - ( event.clientY / $('#canvas').height() ) * 2 +1.2;
  }
 // console.log("WINDOW "+window.innerHeight+" CANVAS "+ $('#canvas').height());
  let raycaster = new THREE.Raycaster();

  raycaster.setFromCamera( mouse, camera );

  let intersects;
  if (mode !== "final"){
    intersects = raycaster.intersectObjects( mouseMeshes, true );
  } else {
    intersects = raycaster.intersectObjects( officeMeshes, true );
  }
 // console.log("MODE",mode,stage)
  if (intersects.length > 0 && !stage == 0 && Math.abs(mouse.x)<=1) {

  


    let clickedOfficeMesh = intersects[0].object.parent.parent;

    let oi = clickedOfficeMesh.userData.office_index
    selectedOfficeIndex = oi
   // console.log("OI"+oi)
    //console.log(clickedOfficeMesh)

    //console.log("person oi "+oi)

     //clickedOffice =  personaMeshes[oi];

     for (let i = 0;i<personaMeshes.length;i++){
        if (personaMeshes[i].userData.office_index == oi){
          clickedPersona = personaMeshes[oi];
          //console.log("persona"+i)
        }
     }

     for (let i = 0;i<officeMeshes.length;i++){
        if (officeMeshes[i].userData.office_index == oi){
          clickedOffice = officeMeshes[oi];
          selectedOfficeIndex = i
         
        }
     }
     
    let tt = 'translate('+labelpos.x+'px,'+labelpos.y+'px) scale(0)'

    $('#mainlabel').css({'transform':tt,'opacity':'0'})

    if (!clickedOfficeMesh.userData.inBuilding) {
      selectedOfficeIndex = oi;
      if (building.children.length == 0){
      // we are choosing our role
      $('#roleButton').css({'display':'block','pointer-events':'auto'});
      $('#roleButton').html('choose '+Constants.text[oi].name);
      for (let i = 0; i<personaMeshes.length; i++){
        if (personaMeshes[i].position.z !== -2){
      
          gsap.to(personaMeshes[i].position, {
          z:-2,
          ease: "sine.inOut",
          duration:.1,
          delay:0
          })

        }
      }

      gsap.to(clickedOfficeMesh.position, {
        z:-1.9,
        ease: "sine.inOut",
        duration:.2,
        delay:.1
      })



      }else{
        // we are choosing our coworkers
        menuToBuilding(oi)
        
      
        //center on building
        tag('build_it','team','add_persona',Constants.text[oi].name)

      }
      //center on building

    } else {
      if (mode == "final"){
        moveToOffice(oi);
      

    }

    }
  }
}
});


//////////////////////////////////////
//   END CLICK ON MENU OR OFFICE    //
//////////////////////////////////////



function animate() {

  //stats.begin();

  animators.forEach((animator, i) => {
    if (animator.mixer && !animator.mixer.clipAction.paused) {
      animator.mixer.update( .02 );
      //console.log("animation "+i)

    }
  });
 // stats.end();
  renderer.render( scene, camera );
  //render();
 


  requestAnimationFrame(animate)

 // controls.update()
  
  GradientUpdater = GradientUpdater + .05

  if (building) {

    building.children.forEach(b => {
    
      if (b.office){
      let testshadermesh = b.office.children.find(child => child.name === 'conduit')
      if (testshadermesh){
        
      //  testshadermesh.material.uniforms.bboxMax.value = new THREE.Vector3(0,Math.sin(GradientUpdater),0);
      //  testshadermesh.material.uniforms.bboxMin.value = new THREE.Vector3(0,Math.sin(GradientUpdater)+.4,0);
      }
    }
    });
  }
}









function spin(office) {
  spinning = true;
}


//////////////////////////////////////
//            USER FLOW             //
//////////////////////////////////////



let startButton = document.getElementById("startButton");
startButton.onclick = () => {
  coworkersOver = false;
  mode = "building";
  gsap.to($('#intro'), {duration:1, css:{opacity:0},onComplete:hideIntro} )
  var mlx = ($('#canvas').width()/2)+($('#canvas').position().left)-($('#mainlabel').width()/2)+'px';
  var mly = ($('#canvas').height()/5)+'px';
  $('#mainlabel').css({'transform':'scale(1) translate('+mlx+','+mly+')','opacity':'0'})
  $('#prompt').css({'transform':'scale(1) translate(-50%,-50%'});
  $('#prompt h2').html('Select a Character Below');
  //temp play all videos
  for(var v = 0; v<videos.length; v++){
    if (videos[v]){
    videos[v].play();
  }
  }
  nextStage();
}

function hideIntro(){
  $('#intro').remove();
}

$("#roleButton").click(function(){
      coworkersOver = false;
      menuToBuilding(selectedOfficeIndex)
      tag('build_it','role','add_persona',Constants.text[selectedOfficeIndex].name)
      addGradient(clickedOffice);
      $('#reset').css({'display':'block'});
      $('#prompt').css({'transform':'scale(0) translate(-50%,-50%'});

       var vFOV = THREE.MathUtils.degToRad( camera.fov ); // convert vertical fov to radians
        var vheight = 2 * Math.tan( vFOV / 2 ) * 1 // visible height
        var vwidth = vheight * camera.aspect;  

      gsap.to(menu.position,{
        //x:.5,
        y:-.80*vheight,
        z:0,
        ease: "sine.inOut",
        duration:.5,

      }).then(() =>{

         var mlx = ($('#canvas').width()/2)+($('#mainlabel').width()/2)+'px';
     var mly = (2*$('#canvas').height()/3)+'px';
   // $('#mainlabel').css({'transform':'scale(1) translate('+mlx+','+mly+')','opacity':'1'})
  $('#prompt').css({'transform':'scale(1) translate(-50%,-50%'});
    $('#prompt h2').html('Select At Least 4 Coworkers');
      })




      scene.remove(background)
     
      background = new createBackground({grainScale: 0.001, colors: [ '#008ad4','#225179' ]})
      scene.add(background)
      
     $("#stage1").css({'display':'none'});

    


})


let renderButton = document.getElementById("renderButton");

renderButton.onclick = () => {
  personaLabelOffset = 300
   mode = "rendering";
  //allow user to zoom closer
  $('#prompt').css({'transform':'scale(0) translate(-50%,-50%'});
  $('#render').css({'display':'none'});
  removeMenu();
  document.getElementById("customize").style.display = 'flex';
  document.getElementById("stage1").style.display = 'none';
  document.getElementById("stage2").style.display = 'none';
  document.getElementById("stage3").style.display = 'none';
  document.getElementById("coworkers").style.display = 'none';
   

  renderBuilding();
  
  var aabb = new THREE.Box3().setFromObject( building );
        var center = aabb.getCenter( new THREE.Vector3() );
        var size = aabb.getSize( new THREE.Vector3() );

        gsap.to( camera.position, {
          duration: 1,
          x: 12,
          y: center.y,
          z: .2, // maybe adding even more offset depending on your model
          onUpdate: function() {
            camera.lookAt( new THREE.Vector3(0,2,0) );
          }
        } );
}

$('#customizeButton').click(function(){
  document.getElementById("customize").style.display = 'none';
  document.getElementById("sign").style.display = 'flex';
  moveCam(sign,4,0)
})

let submitButton = document.getElementById("submitButton");
submitButton.onclick = () => {
  mode = "final";
  $('#resources a').click(function(){
        tag('build_it','resources','resources_link',$(this).text())
        })
  coworkersOver = true;
  moveCam(building,11.2,0)
  document.getElementById("stage1").style.display = 'none';
  document.getElementById("stage3").style.display = 'none';
  document.getElementById("sign").style.display = 'none';
  document.getElementById("stage7").style.display = 'flex';
  addSign(document.getElementById("fname").value);


}



$('#personaPanel #personaClose').click(function(){

    $('#personaPanel').css({'display':'none'});
    if (!PresetOrder){
    document.getElementById("stage7").style.display = 'flex'; 
    }
    roomZoom = false;
    moveCam(building, 11.2,0);


})


function nextStage() {
  //console.log("NEXT STAGE")

  

  if (building.children.length >= 4) {
    //document.getElementById("disabledButton").disabled = false;
    if ($('#renderButton').hasClass('disabled')){
      $('#renderButton').removeClass('disabled')
    }
  }
  if (stage == 0) {
    document.getElementById("stage1").style.display = 'none';
    document.getElementById("stage2").style.display = 'block';
    gsap.to(camera.position, { x: 6, y: 3, z: 0, duration: .6});
    personaLabelOffset = $(window).height()/3;
    
  } if (stage ==1) {
    document.getElementById("stage2").style.display = 'none';
    personaLabelOffset = $(window).height()/3;
    $('#coworkers').css({'display':'flex'})
    //document.getElementById("stage3").style.display = 'block';
    //document.getElementById("message").innerHTML = Constants.text[selectedOfficeIndex].description;
  } 
  stage += 1;
}


//////////////////////////////////////
//        END USER FLOW             //
//////////////////////////////////////



//////////////////////////////////////
//        ADD BUILDING SIGN         //
//////////////////////////////////////


function addSign(buildingName) {

 
  
  config.com = Constants.bisTitles[Math.floor(Math.random() * Constants.bisTitles.length)];
  config.name = buildingName + "-" + config.com;
 // console.log(config)
  nameBuilding(config.name);
 // console.log(createShareURL(config));
  shareURL = createShareURL(config);
  document.getElementById("copyshare").value =  shareURL;
}





//////////////////////////////////////
//        END BUILDING SIGN         //
//////////////////////////////////////





//////////////////////////////////////
//            LOAD PERSONAS         //
//////////////////////////////////////



function addPersona(m,t,n) {
  // load the person
  //console.log("ADD PERSONA"+m+t+n);
  const pgroup = new THREE.Group();
  gltfLoader.load(
      m,
      (gltf) =>
      {
        const bakedTexture = textureLoader.load(t)
        bakedTexture.flipY = false
        bakedTexture.encoding = THREE.sRGBEncoding

        const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })
        bakedMaterial.fog = false;
        const bakedMesh = gltf.scene.children[0]
        

        bakedMesh.material = bakedMaterial;
        glt1 = gltf.scene
        glt1.scale.x = 1
        glt1.scale.y = 1
        glt1.scale.z = 1
        glt1.position.x = 0
        //glt1.position.y = .6
        glt1.position.z = 0
        glt1.userData.office_index = n


       
        pgroup.add(glt1)
        pgroup.userData.office_index = n
        pgroup.userData.inBuilding = false
        pgroup.userData.spinning = false
        pgroup.userData.videos = []


// position personas within menu

        
        var vFOV = THREE.MathUtils.degToRad( camera.fov ); // convert vertical fov to radians
        var vheight = 2 * Math.tan( vFOV / 2 ) * 1 // visible height
        var vwidth = vheight * camera.aspect;  


  
        pgroup.position.x = ((n/10.5) - (.5))*(vwidth*.9)

        //pgroup.position.x = (n/11) - .5
        pgroup.position.y = 0
        pgroup.position.z = -2

        pgroup.scale.x = .1*vwidth
        pgroup.scale.y = .1*vwidth
        pgroup.scale.z = .1*vwidth
        pgroup.persona = glt1

        menu.add(pgroup)
        menu.position.y = -.2*vheight
        personaMeshes[n] = pgroup;
        
        mouseMeshes.push(glt1)
        
        // load the room

        //////////
      }
  )

  gltfLoader.load(
      'models/'+Constants.text[n].rmodel,
      (gltf2) =>
      {

        const bakedTexture = textureLoader.load('textures/'+Constants.text[n].rtexture)
      
        bakedTexture.flipY = false
        bakedTexture.encoding = THREE.sRGBEncoding

        const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })
        bakedMaterial.fog = false;
        const bakedMesh = gltf2.scene.children.find(child => child.name === 'room1')
        const lightMesh = gltf2.scene.children.find(child => child.name === 'bike')
        const extrasMesh = gltf2.scene.children.find(child => child.name === 'extras')
        const video = gltf2.scene.children.find(child => child.name === 'video')
        const video2 = gltf2.scene.children.find(child => child.name === 'video2')
        const video3 = gltf2.scene.children.find(child => child.name === 'video3')
        const video4 = gltf2.scene.children.find(child => child.name === 'video4')

        pgroup.userData.conduit = gltf2.scene.children.find(child => child.name === 'conduit')

        pgroup.userData.officeMaterial = bakedMaterial;
        //look for more videos
        if (video){
          addVideo(n,0,video);
        }
        // quick fix for multiple vids
        if (video2){
          addVideo(n,1,video2);
        }
        if (video3){
          addVideo(n,2,video3);
        }
        if (video4){
          addVideo(n,3,video4);
        }
        //console.log(gltf2.scene)

        //if (bakedMesh){console.log("bake"+Constants.text[n].rmodel)}
        bakedMesh.material = bakedMaterial;
        //mouseMeshes.push(bakedMesh)
        
        

        if(lightMesh){
        // personaMesh.material = bakedMaterial;
          lightMesh.material = Constants.cubeLightMaterial;
          lightMesh.traverse(function (child) {
    
            child.material = Constants.cubeLightMaterial;
            child.layers.enable( BLOOM_SCENE );
            
          });


         }

          if(extrasMesh){
        // personaMesh.material = bakedMaterial;
       // console.log("EXTRAS",extrasMesh)
          var extrasMaterial

          if (Constants.text[n].extrasTexture){
            //console.log("EXTRAS TEXTURE",Constants.text[n].name)
            
            const extrasTexture = textureLoader.load('textures/'+Constants.text[n].extrasTexture)

            extrasTexture.flipY = false
            extrasTexture.encoding = THREE.sRGBEncoding;
            extrasMaterial = new THREE.MeshBasicMaterial({ map: extrasTexture })
            
          } else{
            console.log("EXTRAS DEFAULT",Constants.text[n].name)
            extrasMaterial = Constants.cubeExtrasMaterial;
          }
          
          extrasMesh.material = extrasMaterial;
          extrasMesh.traverse(function (child) {
    
            child.material = extrasMaterial;

          });


         }

        
        const glt2 = gltf2.scene

        officeMeshes[n] =gltf2.scene

        glt2.scale.x = 0
        glt2.scale.y = 0
        glt2.scale.z = 0
        glt2.position.x = 0
        glt2.position.y = 0
        glt2.position.z = 0
        glt2.rotation.y = -Math.PI/4;
        glt2.visible = false;

        pgroup.add(glt2)
        pgroup.office = glt2


        //console.log("office",pgroup.office)

        let actions = [];
        let mixer = new THREE.AnimationMixer(gltf2.scene);
        mixer.addEventListener('loop', (e) => {
          //spinning = false;
          mixer.timeScale = 1;
        });

        gltf2.animations.forEach((clip, i) => {
          let action = mixer.clipAction(clip);
          //action.trim;
          //action.loop = THREE.LoopRepeat;
          
          actions.push(action);

         // console.log("action",action)
        });

        animators[n]={
          mixer: mixer,
          actions: actions,
        }
      }
    )
}


//////////////////////////////////////
//            END PERSONAS          //
//////////////////////////////////////



function removeMenu(){
  camera.remove(menu)
  console.log("Remove Menu");

}

function nameBuilding(name) {
  const loader = new TTFLoader()
  const fontLoader = new THREE.FontLoader()
  //loader.load('novamono.ttf',function ( fnt ) {
   // console.log("clickedSkin", clickedSkin,Constants.skins[clickedSkin].font )
  let f = Constants.skins[clickedSkin].font;

  loader.load(f,function ( fnt ) {
   const font = fontLoader.parse(fnt)
   const geometry = new THREE.TextGeometry( name.toUpperCase(), {
       font: font,
       size: 2,
       height: .5,
       curveSegments: 22,
       bevelEnabled: false,
       bevelThickness: .1,
       bevelSize: 1,
       bevelOffset: 0,
       bevelSegments: 0
    } );

    geometry.center();

    const material = Constants.cubeLightMaterial

    // Create a mesh with the defined geometry and material
    nameMesh = new THREE.Mesh(geometry, material);
   
    
     

     let box = new THREE.Box3().setFromObject( nameMesh );
     //console.log("TEXTparentWIDTH", box.getSize().x );
     let box2 = new THREE.Box3().setFromObject( sign );
    // console.log("SIGNWIDTH", box2.getSize().x,box2.getSize().z );
     sign.add(nameMesh)
     nameMesh.position.set(0,0,0)
     nameMesh.rotation.y = Math.PI / 2;
     nameMesh.scale.set( 7.5/box.getSize().x, 7.5/box.getSize().x, 1);
     
  } );
}
function addClouds(){
    const gltf3Loader = new GLTFLoader();

      gltf3Loader.load(
      'models/'+Constants.cloudModel,
      (gltf3) =>
      {
        cloud1 = gltf3.scene.children.find(child => child.name === 'cloud1')
        cloud1.material = Constants.cloudMaterial;
        cloud1.position.x = -30
        cloud1.position.y = 10
         cloud1.position.z = -35

         cloud1.scale.x = .06
        cloud1.scale.y = .06
        cloud1.scale.z = .06

        cloud1.rotation.y = Math.PI/2;

        cloud2 = gltf3.scene.children.find(child => child.name === 'cloud2')
        cloud2.material = Constants.cloudMaterial;
        cloud2.position.x = -20
        cloud2.position.y = 1
        cloud2.position.z = -40

        cloud2.scale.x = .06
        cloud2.scale.y = .06
        cloud2.scale.z = .06

        cloud2.rotation.y = Math.PI/2;
      })
        
}

function renderBuilding(){

  //add the building structure
      
      for(i=0;i<building.children.length;i++){

      building.children[i].office.scale.x = 1;
      building.children[i].office.scale.y = 1;
      building.children[i].office.scale.z = 1;
    }


    // add background plane


      
    
      let video = document.createElement( 'video' );
          
            video.src = "textures/" + Constants.backgroundVideoMaterial;
          
            video.loop = true;
            video.playsInline = true;
            video.load(); // must call after setting/changing source
            video.play();
            
            let videoTexture = new THREE.VideoTexture(video);
            ///videoTexture.flipY = false;
            
             
            videoTexture.center = new THREE.Vector2(0.5, 0.5); 
            videoTexture.minFilter = THREE.LinearFilter;
            videoTexture.magFilter = THREE.LinearFilter;
            const bg_material =  new THREE.MeshBasicMaterial( {map: videoTexture, side: THREE.DoubleSide, toneMapped: false} );
           
            const bg_geometry = new THREE.PlaneGeometry( 20, 20 );
             // const bg_material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
            const bg_plane = new THREE.Mesh( bg_geometry, bg_material );
           // scene.add( bg_plane );
            bg_plane.position.x = -5;
            bg_plane.scale.x = 1;
            bg_plane.scale.y = 1;
            bg_plane.scale.z = 1;
            bg_plane.rotation.y = Math.PI/2;

            scene.remove(background)
     
             scene.background = videoTexture;

              // Set the repeat and offset properties of the background texture
              // to keep the image's aspect correct.
              // Note the image may not have loaded yet.
              const canvasAspect = canvas.clientWidth / canvas.clientHeight;
              const imageAspect = 16/9;
              const aspect = imageAspect / canvasAspect;
             
              videoTexture.offset.x = aspect > 1 ? (1 - 1 / aspect) / 2 : 0;
              videoTexture.repeat.x = aspect > 1 ? 1 / aspect : 1;
             
              videoTexture.offset.y = aspect > 1 ? 0 : (1 - aspect) / 2;
              videoTexture.repeat.y = aspect > 1 ? 1 : aspect;


    // end background

      const gltf3Loader = new GLTFLoader();

      gltf3Loader.load(
      'models/'+Constants.buildingModel,
      (gltf3) =>
      {
       
        const f1 = gltf3.scene.children.find(child => child.name === 'allfloors')
        f1.material = buildingmaterial;
        f1.material.map.flipY = false;

        sign = gltf3.scene.children.find(child => child.name === 'Sign')
        //console.log("SIGN")
        //console.log(sign)

        gltf3 = gltf3.scene
        
        

        gltf3.scale.x = .27
        gltf3.scale.y = .27
        gltf3.scale.z = .27
        gltf3.position.x = .76
        gltf3.position.y = 0
        gltf3.position.z = 0
        gltf3.rotation.y = Math.PI/4

        /*
        var geo = new THREE.EdgesGeometry( f1.geometry ); // or WireframeGeometry
        var mat = new THREE.LineBasicMaterial( { color: 0x000000 } );
        var wireframe = new THREE.LineSegments( geo, mat );
        f1.add( wireframe );
        */
        building.add(gltf3)
       
        
      })

      scene.add(cloud1)
        scene.add(cloud2)
        console.log ("addclouds");
        gsap.to(cloud1.position, {
          z:40,
          duration: 60,
          repeat: 100,
          ease:"linear",
        })
        gsap.to(cloud2.position, {
          z:35,
          duration: 90,
          repeat: 100,
          ease:"linear",
        })
      



}

function changeSkin(i) {
  buildingmaterial.map = skins[i];
  buildingmaterial.side = THREE.FrontSide;
  clickedSkin = i;
  config.skin = i;
  //buildingmaterial.map.flipY = false;

  // wireframe

}


//////////////////////////////////////
//              MENU                //
//////////////////////////////////////

function menuToBuilding(oi){
     // console.log("menuToBuilding"+oi);
      if (config.roomOrder!==""){
        config.roomOrder+="p"+oi;
      } else {
        config.roomOrder+=oi;
      }

      $('#myapps').append("<strong>"+Constants.text[oi].domain+"</strong><br>");
      $('#myapps').append(Constants.text[oi].resourcesSummary+"<br><br>");


      const pos = Constants.buildingPositions[buildingPositionCounter];
      //console.log("menu2pos",pos)
      if (pos.y > topfloor){
        topfloor = pos.y
      }
      clickedPersona = personaMeshes[oi]
      clickedPersona.persona.position.y = .5;
      console.log("POSY"+clickedPersona.children[0].position.y );
      //console.log("m2b-office",personaMeshes[oi])
      clickedOffice = personaMeshes[oi].office

      buildingPositionCounter += 1;
      
      
      personaMeshes[oi].userData.inBuilding = true;
      addGradient(clickedOffice);

        gsap.to( camera.position, {
          duration: 1,
          x: 10,
          y: 0,
          z: 0, // maybe adding even more offset depending on your model
          onUpdate: function() {
            camera.lookAt( new THREE.Vector3(0,0,0) );
          }
        } );

        gsap.to(building.position,{
          duration:1,
          y:-pos.y/2
        })
      

      gsap.to(clickedPersona.position, {
        y:-5,
        ease: "back.in(1.2)",
        duration:.3
      }).then(() =>{
       // clickedOffice.add(clickedPersona)
        building.add(clickedPersona);
        clickedPersona.office.visible = true;
        clickedPersona.office.scale.x = 1;
        clickedPersona.office.scale.y = 1;
        clickedPersona.office.scale.z = 1;
        clickedPersona.position.y = 5
        clickedPersona.position.z = .5
        clickedPersona.position.x = 0
        clickedPersona.rotation.y = Math.PI/4
        clickedPersona.scale.x = 1
        clickedPersona.scale.y = 1
        clickedPersona.scale.z = 1

        
      }).then(() => {
        
        clickedPersona.position.y = 5;
      
        gsap.to(clickedPersona.position, {
          y: pos.y*Constants.gridy,
          x: pos.x*Constants.gridx,
          z: pos.z*Constants.gridz,
          duration: .6,
          repeat: 0,
          yoyo: false
        }).then(() => {

        })
      });
      gsap.to(clickedPersona.rotation, {
        x: 0,
        y: Math.PI/2,
        z: 0,
        duration: .6,
        repeat: 0,
        delay:.6
      })
      gsap.to(clickedPersona.scale, {
        x: .27,
        y: .27,
        z: .27,
        duration: .6,
        repeat: 0,
        delay:.6
      });
      


      addGradient(clickedOffice);
     // config.roomOrder+="p"+oi;
      nextStage();

}



function menuToBuildingAuto(oi){
   
      $('#myapps').append("<strong>"+Constants.text[oi].domain+"</strong><br>");
      $('#myapps').append(Constants.text[oi].resourcesSummary+"<br><br>");

      const pos = Constants.buildingPositions[buildingPositionCounter];
      //console.log("menu2pos",pos)
      gsap.to(building.position,{
          duration:1,
          y:-pos.y/2
        })
      clickedPersona = personaMeshes[oi]
      menu.remove(clickedPersona);
      building.add(clickedPersona);
      clickedPersona.persona.position.y = .5;
      
      clickedOffice = personaMeshes[oi].office
      clickedOffice.visible = true;
      
      console.log("OFFICE",clickedOffice)
      
      buildingPositionCounter += 1;

      console.log("auto"+oi+"A");
      
     
      clickedPersona.userData.inBuilding = true;

      console.log("auto"+oi+"B");
      addGradient(clickedOffice);
      console.log("auto"+oi+"C");
      
     
        building.add(clickedPersona);
        clickedPersona.office.visible = true;
        clickedPersona.office.scale.x = 1;
        clickedPersona.office.scale.y = 1;
        clickedPersona.office.scale.z = 1;
        clickedPersona.position.y = pos.y*Constants.gridy
        clickedPersona.position.z = pos.z*Constants.gridz
        clickedPersona.position.x = pos.x*Constants.gridx
        clickedPersona.rotation.y = Math.PI/4
        clickedPersona.scale.x = .27
        clickedPersona.scale.y = .27
        clickedPersona.scale.z = .27
        clickedPersona.rotation.y = Math.PI/2
        clickedPersona.rotation.x = 0;
        clickedPersona.rotation.z= 0;

        
     
      
    
      console.log("auto"+oi+" posX"+pos.x*Constants.gridx+" posY"+pos.y*Constants.gridy);
     console.log("auto"+oi+"Z"+buildingPositionCounter);
     console.log("BUILDING",building.children[building.children.length-1])
     console.log(clickedPersona)
}


//////////////////////////////////////
//          END MENU                //
//////////////////////////////////////




//////////////////////////////////////
//                SHARE             //
//////////////////////////////////////

$('#share .facebook').click(function(){
  fbShare();
})
$('#share .twitter').click(function(){
  twitterShare();
})
$('#share .linkedin').click(function(){
  linkedinShare();
})
$('#share .download').click(function(){
  screenShot();
})

$('#share .close').click(function(){
  $('#share').css({'display':'none'})
  mode = "final";
  $('#reset').css({'display':'block'});
})
$('#share .copy').click(function(){
  urlShare();
})
$('#share-button').click(function(){
  $('#share').css({'display':'flex'})
  mode = "share";
  $('#reset').css({'display':'none'});
})
$('#resources-button').click(function(){
  $('#resources').css({'display':'flex'});
})
$('#resources .close').click(function(){
  $('#resources').css({'display':'none'});
})
$('#reset').click(function(){
  reset();
})


function fbShare() {
        var winTop = (screen.height / 2) - (400 / 2);
        var winLeft = (screen.width / 2) - (400 / 2);
        window.open('https://www.facebook.com/sharer/sharer.php?s=100&p[title]=' + Constants.share.title + '&p[summary]=' + Constants.share.description + '&p[url]=' + shareURL + '&p[images][0]=' + Constants.share.image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+300+',height='+300);
    }
function twitterShare() {
        var winTop = (screen.height / 2) - (400 / 2);
        var winLeft = (screen.width / 2) - (400 / 2);
        window.open('https://twitter.com/intent/tweet?text=' + Constants.share.title + ' ' + Constants.share.description + '&url=' + shareURL, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+300+',height='+300);
    }
 function linkedinShare() {
        var winTop = (screen.height / 2) - (400 / 2);
        var winLeft = (screen.width / 2) - (400 / 2);
        window.open('https://www.linkedin.com/shareArticle?title=' + Constants.share.title + '&summary=' + Constants.share.description + '&url=' + shareURL+'&source=solidworks', 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+300+',height='+300);
    }

function urlShare(){
  /* Get the text field */
  let copyText = document.getElementById("copyshare");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  document.execCommand("copy");
}

//////////////////////////////////////
//             END SHARE            //
//////////////////////////////////////



function addCity() {
  //this is the backdrop model
  gltfLoader.load(
      'models/city.glb',
      (gltf) =>
      {
        const i = 1
        const bakedMaterial = new THREE.MeshBasicMaterial({ color: '#FFCC00' })
        const bakedMesh = gltf.scene.children
        bakedMesh.material = bakedMaterial;
        glt1 = gltf.scene
        glt1.scale.x = .5
        glt1.scale.y = .5
        glt1.scale.z = .5
        glt1.position.x = -30
        glt1.position.y = -1;
        glt1.position.z = 1
        glt1.office_index = i;
        scene.add(glt1)
      }
  )
}


function lightConnections(oi){
  //console.log("LIGHT",oi)
    dimConnections()
    personaMeshes[oi].userData.conduit.visible = true;

    for(var i=0;i<personaMeshes.length;i++){
    //personaMeshes[i].userData.shadow.visible = true;
    personaMeshes[i].userData.officeMaterial.color.set('#333')
    }
    personaMeshes[oi].userData.officeMaterial.color.set('#ffffff')
    for(var i=0;i< Constants.text[oi].connections.length;i++){
   // personaMeshes[Constants.text[oi].connections[i]].userData.shadow.visible = false;
    personaMeshes[Constants.text[oi].connections[i]].userData.officeMaterial.color.set('#ffffff')
    personaMeshes[Constants.text[oi].connections[i]].userData.conduit.visible = true;
    }
}

function dimConnections(){
  for(var i=0;i<personaMeshes.length;i++){
    personaMeshes[i].userData.conduit.visible = false;
    personaMeshes[i].userData.officeMaterial.color.set('#ffffff')
   // personaMeshes[i].userData.shadow.visible = false;
  }
}





//////////////////////////////////////
//                 UTILS            //
//////////////////////////////////////








function worldPosToScreen(object,camera) {
    var vector = new THREE.Vector3();
    var widthHalf = 0.5 * window.innerWidth;
    var heightHalf = 0.5 * window.innerHeight;
    object.updateMatrixWorld (); // This code is important to update the object before getting under world coordinate / world matrix * /
    vector.setFromMatrixPosition(object.matrixWorld);
    vector.project(camera);
    vector.x = (vector.x * widthHalf) + widthHalf;
    vector.y = -(vector.y * heightHalf) + heightHalf;
    return {
        x: vector.x,
        y: vector.y
    };
}


function createPath(pos1, pos2) {
  // const material = new THREE.LineBasicMaterial({ color: 0x0000ff });

  const curve = new THREE.QuadraticBezierCurve3(
    pos1,
    new THREE.Vector3( 3, 0, 0 ),
    pos2
  );

  points = curve.getPoints( curveResolution );

  const geometry = new THREE.BufferGeometry().setFromPoints( points );
  line = new THREE.Line( geometry );

  scene.add( line );
}

function moveCam(mesh,distance,lr){
  var aabb = new THREE.Box3().setFromObject( mesh );
        var center = aabb.getCenter();
        // center += new THREE.Vector3(distance,0,lr);
        var size = aabb.getSize();
        var ymov = center.y - (size.y/4);
        if (mesh == building){
          ymov = center.y - .4
        }
        gsap.to( camera.position, {
          duration: 1,
          x: center.x+distance,
          y: ymov,
          z: center.z+lr,
          onUpdate: function() {
            //camera.lookAt( new THREE.Vector3(center.x,center.y,center.z-lr) );
          }
        } );
}


function moveToOffice(oi){
        moveCam(personaMeshes[oi], 2,0);
       
        document.getElementById("stage7").style.display = 'none';
        $('#personaDescription').html(Constants.text[oi].description)
        $('#personaResources').html(Constants.text[oi].resources)
        $('#personaName').html(Constants.text[oi].name)
        $('#personaRole').html(Constants.text[oi].role)
        $('#personaDomain').html(Constants.text[oi].domain)
        
        $('#personaConnections').html('')
        
        for(var c=0; c < Constants.text[oi].connections.length;c++){
          
         // $('#personaConnections').append(Constants.text[Constants.text[oi].connections[c]].name)

         if(personaMeshes[Constants.text[Constants.text[oi].connections[c]].id].userData.inBuilding){
          let ca = Constants.text[Constants.text[oi].connections[c]].avatar
          $('#personaConnections').append("<img class='avatar' src='./"+ca+"' data-persona='"+Constants.text[Constants.text[oi].connections[c]].id+"'>")
          }
        }


        $('#personaConnections img').click(function(){
            moveToOffice($(this).data("persona"));
          })


        $('#personaPanel').css({'display':'flex'});
        $('#resources').css({'display':'none'});
          
        
        roomZoom = true;
        tag('build_it','explore','explore_persona',Constants.text[oi].name)

        $('#personaPanel a').click(function(){
         tag('build_it','explore','persona_link',$(this).text())
        })
  }


window.addEventListener('resize', () => {
  // Update sizes
  resize();
  
})

function resize(){
  // Update sizes
  sizes.width = window.innerWidth - canvasHOffset
  sizes.height = window.innerHeight

  if (window.innerWidth < 768){
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight/2;
    canvasHOffset = 0;
  }

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // get max width of menu

  var vFOV = THREE.MathUtils.degToRad( camera.fov ); // convert vertical fov to radians
  var vheight = 2 * Math.tan( vFOV / 2 ) * 1 // visible height
  var vwidth = vheight * camera.aspect;  

  console.log("camerawidth",vwidth)
 
  for (var mp = 0; mp<menu.children.length;mp++){
    var mpx = menu.children[mp].position.x 
    //console.log(mpx)
   // pgroup.position.x = ((mp/11) - .5)*vwidth
    menu.children[mp].position.x = ((mp/10.5) - .5)*(vwidth*.9)
    menu.children[mp].scale.x =  .1*vwidth
    menu.children[mp].scale.y =  .1*vwidth
    menu.children[mp].scale.z =  .1*vwidth
   // menu.children[mp].setScale(new THREE.Vector(vwidth,vwidth,vwidth));
  }

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  //BLOOM CODE //
  bloomComposer.setSize( sizes.width, sizes.height );
  finalComposer.setSize( sizes.width, sizes.height );
  //END BLOOM //
}


function checkLoad(){
     // console.log("checkload");
      //check that we have a persona + office for each
      let loaded = true;
      for (var i =0; i<Constants.text.length;i++){
        if(!personaMeshes[i]){
          loaded = false;
        }
        if(!personaMeshes[i].office){
          loaded = false;
        }
      }
      if (loaded){
      clearInterval(loadTime)
      //console.log("checkload",personaMeshes)
      addPresetBuilding(PresetOrder);
      }
}

function addPresetBuilding(order) {
 // scene.remove(background)
     
 // background = new createBackground({grainScale: 0.001, colors: [ '#008ad4','#225179' ]})
  //scene.add(background)
  removeMenu();
  // skip all the intro scenes + just show the end.
  mode = "final";
  stage = 5;
  
      let roomProps = order.split("z8z")
      let roomOrder = roomProps[0]
      let name = roomProps[1]
      let skin = roomProps[3]
      let com = roomProps[2]

      let roomArr = roomOrder.split("p");
      //console.log(roomArr);
      //console.log(personaMeshes);

      $("#coworkers").css({'display':'none'});
      $("#stage1").css({'display':'none'});
      $("#stage2").css({'display':'none'});
      $("#stage3").css({'display':'none'});
      $("#myworld").css({'display':'flex'});
      $("#myworld p").prepend("<h2>Welcome to "+name.toUpperCase()+"</h2>"+name.toUpperCase()+" ")
      $("#emw").html("at "+name.toUpperCase())
      $("#myworld button").append(" "+name.toUpperCase())
      $("#myworld button").click(function(){
      $("#myworld").css({'display':'none'});
      $("#myworldExplore").css({'display':'flex'});




      for (var i=0;i<roomArr.length;i++){
        //console.log(i+"roomArr")
        menuToBuildingAuto(roomArr[i]);
      }
      
      renderBuilding();
      changeSkin(skin);
      nameBuilding(name);
      for(var v = 0; v<videos.length; v++){
        if (videos[v]){
        videos[v].play();
      }
      }

  })

  

 $('#buildButton').click(function(){
        tag('build_it','sharedbuilding','referral','buildmyown');
        })
$('#resources a').click(function(){
        tag('build_it','resources','resources_link',$(this).text())
        })

 /* gsap.to( camera.position, {
          duration: 1,
          x: 10,
          y: 0,
          z: .2, // maybe adding even more offset depending on your model
          onUpdate: function() {
            camera.lookAt( new THREE.Vector3(0,0,0) );
          }
        } );
*/
  camera.lookAt( new THREE.Vector3(0,3.5,0) )
  moveCam(building, 10,0);
}

function reset(){
  //remove sign writing from building
  if(sign && sign.children>0){sign.remove(nameMesh);}
  //remove people from building
    for (var i = 0; i<personaMeshes.length; i++){
      if (personaMeshes[i].userData.inBuilding){
        personaMeshes[i].userData.inBuilding = false;
        building.remove(personaMeshes[i]);

        //hide offices
        personaMeshes[i].office.scale.x = 0;
        personaMeshes[i].office.scale.y = 0;
        personaMeshes[i].office.scale.z = 0;


        let n = personaMeshes[i].userData.office_index

        
        
        var vFOV = THREE.MathUtils.degToRad( camera.fov ); // convert vertical fov to radians
        var vheight = 2 * Math.tan( vFOV / 2 ) * 1 // visible height
        var vwidth = vheight * camera.aspect;  


  
        personaMeshes[i].position.x = ((n/10.5) - (.5))*(vwidth*.9)

        personaMeshes[i].children[0].position.y = 0
        personaMeshes[i].position.y = 0 //-.4
        personaMeshes[i].position.z = -2

        personaMeshes[i].scale.x =  .1*vwidth
        personaMeshes[i].scale.y = .1*vwidth
        personaMeshes[i].scale.z = .1*vwidth

        personaMeshes[i].rotation.x = 0;
        personaMeshes[i].rotation.y = 0;
        personaMeshes[i].rotation.z = 0;
        
        menu.add(personaMeshes[i])
        
      }
    }
  //remove building from building!
  building.remove(building.children[0]);
  scene.background = null;
   background = new createBackground({grainScale: 0.001, colors: [ '#008ad4','#225179' ]})
      scene.add(background)
  scene.remove(cloud1);
  scene.remove(cloud2);
  //reset menu position
  menu.position.x = .05;
  menu.position.y = -.2*vheight
  camera.add(menu);
  //reset role text'
  mode = "building";
  config = {
  roomOrder: "",
  name: "",
  com: "",
  skin: "0"
  };


  $('#reset').css({'display':'none'});
  $("#coworkers").css({'display':'none'});
  $("#customize").css({'display':'none'});
  $("#stage1").css({'display':'none'});
  $("#stage2").css({'display':'flex'});
  $("#stage3").css({'display':'none'});
  $("#stage7").css({'display':'none'});
  $("#resources").css({'display':'none'});
  $("#personaPanel").css({'display':'none'});
  $("#sign").css({'display':'none'});
  $("#myworld").css({'display':'none'});
  $('#roleButton').css({'display':'none'});
  $('#myapps').html('');
  $('#prompt h2').html('Select a Character Below');
  stage = 1;
  buildingPositionCounter = 0;
  if (!$('#renderButton').hasClass('disabled')){
      $('#renderButton').addClass('disabled')
    }

  resize()
}

function animateMe(oi){
    //console.log(oi,"mixer")
    //animators[oi].mixer.timeScale = 1
   
    
    if ( animators[oi].mixer){
    animators[oi].mixer.timeScale = 1
    for(var i = 0; i<animators[oi].actions.length; i++){

      animators[oi].actions[i].play();
     // console.log("PLAY",oi,i)
    }
     }

}


function addVideo(oi,s,v){
  let video = document.createElement( 'video' );
   
            video.src = "textures/" + Constants.text[oi].video[s];

            video.loop = true;
            video.playsInline = true;
            video.load(); // must call after setting/changing source
          //  VIDEOS.push(video);
            videos.push(video)
           // console.log("VIDEO",videos);
            
            let videoTexture = new THREE.VideoTexture(video);
             videoTexture.flipY = false;
            
             // weird exception for elaine;
             if (oi !== 9){
            
             videoTexture.rotation = -Math.PI/2;
            }
             videoTexture.center = new THREE.Vector2(0.5, 0.5); 
            videoTexture.minFilter = THREE.LinearFilter;
            videoTexture.magFilter = THREE.LinearFilter;
            const videoMaterial =  new THREE.MeshBasicMaterial( {map: videoTexture, side: THREE.DoubleSide, toneMapped: false} );
            v.material = videoMaterial;
}

//////////////////////////////////////
//            END  UTILS            //
//////////////////////////////////////


//////////////////////////////////////
//        BLOOM FUNCTIONS            //
//////////////////////////////////////

function setupScene() {

        scene.traverse( disposeMaterial );
        scene.children.length = 0;

        const geometry = new THREE.IcosahedronGeometry( 1, 15 );

        for ( let i = 0; i < 50; i ++ ) {

          const color = new THREE.Color();
          color.setHSL( Math.random(), 0.7, Math.random() * 0.2 + 0.05 );

          const material = new THREE.MeshBasicMaterial( { color: color } );
          const sphere = new THREE.Mesh( geometry, material );
          sphere.position.x = Math.random() * 10 - 5;
          sphere.position.y = Math.random() * 10 - 5;
          sphere.position.z = Math.random() * 10 - 5;
          sphere.position.normalize().multiplyScalar( Math.random() * 4.0 + 2.0 );
          sphere.scale.setScalar( Math.random() * Math.random() + 0.5 );
          scene.add( sphere );

          if ( Math.random() < 0.25 ) sphere.layers.enable( BLOOM_SCENE );

        }

        render();

      }

      function disposeMaterial( obj ) {

        if ( obj.material ) {

          obj.material.dispose();

        }

      }

      function render() {

            renderBloom( true );
            // render the entire scene, then render bloom scene on top
            finalComposer.render();

      }

      function renderBloom( mask ) {

        if ( mask === true ) {

          scene.traverse( darkenNonBloomed );
          bloomComposer.render();
          scene.traverse( restoreMaterial );

        } else {

          camera.layers.set( BLOOM_SCENE );
          bloomComposer.render();
          camera.layers.set( ENTIRE_SCENE );

        }

      }

      function darkenNonBloomed( obj ) {

        if ( obj.isMesh && bloomLayer.test( obj.layers ) === false ) {

          materials[ obj.uuid ] = obj.material;
          obj.material = darkMaterial;

        }

      }

      function restoreMaterial( obj ) {

        if ( materials[ obj.uuid ] ) {

          obj.material = materials[ obj.uuid ];
          delete materials[ obj.uuid ];

        }

      }
//////////////////////////////////////
//        END BLOOM FUNCTIONS       //
//////////////////////////////////////

function screenShot(){
  /*
  var w = window.open('', '');
    w.document.title = "Screenshot";
    //w.document.body.style.backgroundColor = "red";
    var img = new Image();
    // Without 'preserveDrawingBuffer' set to true, we must render now
    renderer.render(scene, camera);
    img.src = renderer.domElement.toDataURL();
    w.document.body.appendChild(img); 
  */
    scene.remove(cloud1)
    scene.remove(cloud2)
    

    resizePhoto();
    logosprite.visible = true;
    var a = document.createElement('a');
   
    renderer.render(scene, camera);
    

    a.href = renderer.domElement.toDataURL().replace("image/png", "image/octet-stream");
    a.download = 'buildit.png'
    a.click();
    logosprite.visible =false;
    scene.add(cloud1)
    scene.add(cloud2)
    resize();
}


function resizePhoto(){
  // Update sizes
  sizes.width = 1200
  sizes.height = 1200

 

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // get max width of menu

  var vFOV = THREE.MathUtils.degToRad( camera.fov ); // convert vertical fov to radians
  var vheight = 2 * Math.tan( vFOV / 2 ) * 1 // visible height
  var vwidth = vheight * camera.aspect;  

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  //BLOOM CODE //
  bloomComposer.setSize( sizes.width, sizes.height );
  finalComposer.setSize( sizes.width, sizes.height );
  //END BLOOM //
}


init()
animate()

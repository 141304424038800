import * as THREE from 'three'

export const Constants = {
  gridz: .76,
  gridy: 1.08,
  gridx: .76,
  //buildingModel:'building4.glb',
  buildingModel:'building5.glb',
  backgroundMaterial:'bgmat.png',
  backgroundVideoMaterial: 'BG-Vid_w Lights0001-0060.mp4',
  buildingPositions: [
  
    {x: 1, y: 0, z: 0 },
    {x: -1, y: 1, z: -2 },
    {x: 0, y: 1, z: 1 },
    {x:  -1, y: 2, z: 0 },
    {x:  -2, y: 2, z: 3 },
    {x: -2, y: 3, z: 1 },
    {x: -2, y: 3, z: -1 },
    {x: -2, y: 3, z: -3 },

    {x: -4, y: 4, z: -1 },
    {x: -3, y: 4, z: 2 },
    {x: -4, y: 5, z: 1 },
    
  ],
  emptyPositions: [
    {x: 0, y: -.5, z: -1 },
    {x: 0, y: -.5, z: 1 },
    {x: -1, y: -.5, z: -2 },
    {x: -1, y: -.5, z: 2 },
    {x: -2, y: -.5, z: -3 },
    {x: -2, y: -.5, z: 3 },
    {x: 0, y: -1.5, z: 1 },

    {x: -1, y: .5, z: 0 },




    {x: -1, y: .5, z: -2 },
    {x: -1, y: .5, z: 2 },
    {x: -2, y: .5, z: -3 },
    {x: -2, y: .5, z: 3 },

    {x: -2, y: 1.5, z: -3 },
    {x: -2, y: 1.5, z: 3 },
    {x: -2, y: 1.5, z: -1 },
    {x: -2, y: 1.5, z: 1 },

    {x: -3, y: 2.5, z: -2 },
    {x: -3, y: 2.5, z: 2 },
    {x: -2, y: 2.5, z: -3 },
    {x: -2, y: 2.5, z: 3 },

    {x: -3, y: 2.5, z: 0 },

    //{x: -3, y: 3.5, z: -2 },
    {x: -3, y: 3.5, z: 2 },
    {x: -5, y: 3.5, z: 0 },
    {x: -2, y: 3.5, z: 3 },
    {x: -4, y: 3.5, z: -1 },
    {x: -4, y: 3.5, z: 1 },

  ],
  cubeLightMaterial: new THREE.MeshPhongMaterial({ color: 0xFFffff}),
  cloudModel:'clouds.glb',
  cloudMaterial: new THREE.MeshPhongMaterial({ color: 0xcccccc}),
  cubeExtrasMaterial: new THREE.MeshPhongMaterial({ color: 0x010101 }),
  foundationTexture: "foundation-texture.jpg",
  text: [

    { id: 0, name: "Sam Shop",
    avatar: "avatar_Sam.png",
    role: "Shop Floor Programmer",
    domain: "Manufacturing",
    summary:"I manage the machines on the shop floor.",
    shortdesc:"If my life were a machine, I'd be the person running around with a wrench and oil can. Software that helps me keep an eye on quality, downtime and inventory is critical to making sure all inputs into the system keep us moving at pace without risk.",
    description: "With Shop Floor Programmer, part of the <strong>3D</strong>EXPERIENCE Works portfolio, I can get automatic updates on design changes that will disrupt the production runs.",
    resources:"<a href='https://www.solidworks.com/product/shop-floor-programmer' target='_blank'>Learn more about manufacturing roles</a><br><a href='https://www.solidworks.com/media/webinar-introduction-nc-programming-roles-3dexperience-works-platform' target='_blank'>Webinar: Programming roles</a><br><a href='http://www.youtube.com/watch?v=pXcbE5ZQ-_g' target='_blank'>First Look at Shop Floor Programmer</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/product/shop-floor-programmer' target='_blank'>Shop Floor Programmer</a>",
    pmodel:"sam.glb",
    ptexture:"sam.jpg",
    rmodel:"room-sam-92b.glb",
    rtexture:"room-sam-92.jpg",
    extrasTexture:"extras-sam-92.jpg",
    connections:[3,10]
    },

    { id: 1, name: "Ben Business", 
    avatar: "avatar_Ben.png",
    role: "Collaborative Industry Innovator",
    domain: "Collaboration", 
    summary:"I run a business unit.", 
    shortdesc:"I help make day-to-day decisions as part of the business unit I lead. I rely heavily on the right kind of data flow - ensure constant alignment, growth and wider organizational harmony.",
    resources:"<a href='https://discover.solidworks.com/unite-your-teams-maximize-efficiency-and-drive-more-innovation#_ga=2.242024578.1488777629.1639412031-6a869100-416c-11ec-a733-7dcd10b72fcf' target='_blank'>Read about how to Drive More Innovation</a><br><a href='https://www.solidworks.com/product/collaborative-business-innovator#_ga=2.144704436.1488777629.1639412031-6a869100-416c-11ec-a733-7dcd10b72fcf' target='_blank'>Learn more about solutions for your team</a><br><a href='https://www.solidworks.com/media/five-fast-facts-collaborative-industry-innovator' target='_blank'>Fast Facts on Collaborative Industry Innovator</a>",
    description: "Collaborative Business Innovator connects all the data and people into a single space where I have all the information I need. It ensures we can keep the business moving and keep customers happy with new innovative products.", 
    resourcesSummary:"<a href='https://www.solidworks.com/product/collaborative-business-innovator' target='_blank'>Social Business Analyst</a>",
    pmodel:"ben.glb", 
    ptexture:"ben.jpg", 
    rmodel:"room-ben-92.glb", 
    rtexture:"room-ben-92.jpg",
    video:["Screen-Projection_Ben.mp4"],
    connections:[2,8,9]
    },
    
    { id: 2, name: "Pete Project",
    avatar: "avatar_Pete.png",
    role: "Project Planner",
    domain: "Management/Collaboration", 
    summary:"I decide which products we develop.",
    shortdesc:"I sit at the center of a lot of talented people, they often work in different time zones, occupy different roles, use distinct apps and are assigned different objectives. Without one interconnected tech platform, I'm not sure how it will work. Probably not well.",
    description: "Using Project Planner in the <strong>3D</strong>EXPERIENCE Works portfolio keeps me connected to where the products are in the development cycle so I can ensure the collaboration and hand offs between departments happens efficiently/seamlessly.", 
    resources:"<a href='https://www.solidworks.com/domain/data-management-collaboration#brick--5221--default--en' target='_blank'>Learn about additional management roles</a><br><a href='https://www.solidworks.com/media/keep-projects-and-teams-track-project-planner' target='_blank'>Watch how to keep projects on time</a><br><a href='https://www.solidworks.com/media/project-planner-datasheet' target='_blank'>Project Planner Data Sheet</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/media/keep-projects-and-teams-track-project-planner' target='_blank'>Project Planner</a>",
    pmodel:"pete.glb", 
    ptexture:"pete.jpg", 
    rmodel:"room-pete-92.glb", 
    rtexture:"room-pete-92.jpg",
    extrasTexture:"extras-pete-92B.jpg",
    connections:[1,3,8]
     },

    { id: 3, name: "Megan Manager", 
    avatar: "avatar_Megan.png",
    role: "Lean Team Player",
    domain: "Manufacturing Operations",  
    summary:"The engineering team reports to me.", 
    shortdesc:"My focus is to ensure the full spectrum of product design is running as smoothly as possible. The tools I use are the nervous system that keep the body of work growing - and “a connected tool set is paramount to achievement.",
    description: "Lean Team Player provides me with an efficient way to run meetings and provides visibility into what everyone is working on and any dependencies that effect the delivery of the product. With the collaboration tools we can create more stakeholder involvement, improve communication, and eliminate silos.", 
    resources:"<a href='https://www.solidworks.com/lp/manufacturing-without-limits' target='_blank'>Learn more about Manufacturing roles</a><br><a href='https://www.solidworks.com/media/webinar-learn-how-communicate-better-throughout-your-organization' target='_blank'>Webinar: How to Communicate Better</a><br><a href='https://www.solidworks.com/media/first-look-video-lean-team-player' target='_blank'>First Look at Lean Team Player</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/lp/manufacturing-without-limits' target='_blank'>Lean Team Player</a>",
    pmodel:"megan.glb", 
    ptexture:"megan.jpg", 
    rmodel:"room-megan-92.glb", 
    rtexture:"room-megan-92.jpg",
    video:["Screen-Big_Megan.mp4","Screen-Small-1_Megan.mp4","Screen-Small-2_Megan.mp4","Screen-Small-3_Megan.mp4"],
    connections:[2,4,5,6,7]
    },
    
    { id: 4, name: "Dan Designer",
    avatar: "avatar_Dan.png",
    role: "3DSculptor",
    domain: "Design",
    summary:"I make the organic shapes.",
    shortdesc:"Designing products and making art are one in the same to me. I use tools that allow me to create anything I can imagine. Flexibility is key, both for my designs and for communicating with my teammates. Being able to easily connect with the rest of my team and stakeholders gives me the time and resources I need to let my imagination soar.",
    description: "With 3D Sculptor, I can quickly generate initial concepts and easily access older versions. The Sub-D modeling capabilities allow me to create any organic shape I can imagine, and switching back to parametric modeling tools in 3D Creator is a snap. Sharing my work and collecting feedback is easy and intuitive: my 3D models are flexible, and it’s easy to modify them during a live design review to capture feedback from my team.",
    resources:"<a href='https://www.solidworks.com/product/3d-sculptor' target='_blank'>Learn more about 3D Sculptor</a><br><a href='https://www.solidworks.com/media/get-freedom-create-incredible-designs-anywhere' target='_blank'>Learn how to Create Designs Anywhere</a><br><a href='https://www.solidworks.com/media/jason-pohl-designs-modeling-digital-clay-3d-sculptor' target='_blank'>Video: 3D Sculptor Tutorial</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/product/3d-sculptor' target='_blank'>3D Sculptor</a>",
    pmodel:"dan.glb",
    ptexture:"dan.jpg",
    rmodel:"room-dan-98b.glb",
    rtexture:"room-dan-98b.jpg",
    connections:[3,7]
     },
    
    { id: 5, name: "Debbie Drafter", 
    avatar: "avatar_Debbie.png",
    role: "<strong>3D</strong>EXPERIENCE DraftSight",
    domain: "Design",  
    summary:"I use 2D tools to design products",
    shortdesc:"My work consists of creating 2D drawings needed to build products and provide shop floor dimensions. My job is made much easier by tools that allow me to easily create, access and share drawings with colleagues and external partners, and the ability to preview work on the go.", 
    description: "With <strong>3D</strong>EXPERIENCE DraftSight, part of the <strong>3D</strong>EXPERIENCE Works portfolio, I can easily share drawings and 2D design files with  my team members so we are never out of sync.", 
    resources:"<a href='https://www.draftsight.com/product/3dexperience-draftsight' target='_blank'>Learn more about DraftSight</a><br><a href='https://www.solidworks.com/media/ebook-discover-benefits-connecting-draftsight-cloud' target='_blank'>Read how to connect DraftSight to the cloud</a><br><a href='https://www.draftsight.com/media/introduction-3dexperience-draftsight' target='_blank'>Introduction to <strong>3D</strong>EXPERIENCE DraftSight</a>",
    resourcesSummary:"<a href='https://www.draftsight.com/product/3dexperience-draftsight' target='_blank'><strong>3D</strong>EXPERIENCE DraftSight</a>",
    pmodel:"debbie.glb", 
    ptexture:"debbie.jpg", 
    rmodel:"room-debbie-92.glb", 
    rtexture:"room-debbie-92.jpg",
    video:["Screen_Debbie.mp4"],
    connections:[3,7]
     },

    { id: 6, name: "Adam Analyst", 
    avatar: "avatar_Adam.png",
    domain: "Simulation",  
    role:"Analyst",
    summary:"I look for ways to minimize mass, reduce cost and reduce product failures.",
    shortdesc:"I am concernend with the fit, function, manufacturability and durability of the products we design. My goal is to virtually test our designs to ensure they will perform as designed in the real world with the goal to minimize mass, reduce costs, and reduce the chances of failure.", 
    description: "Structural Mechanics Engineer within the <strong>3D</strong>EXPERIENCE Works portfolio, allows me to easily get the latest designs for validation from my colleagues and share them with the rest of the stakeholders. The cloud-based tools provide me with the computing power needed to run simulations from anywhere.",
    resources: "<a href='https://www.solidworks.com/lp/cloud-enabled-simulation' target='_blank'>Learn more about Simulation tools</a><br><a href='https://discover.solidworks.com/research-offers-guidance-for-making-more-informed-engineering-decisions' target='_blank' >Read how to get more value out of simulation</a><br><a href='https://www.solidworks.com/media/first-look-durability-3dexperience-works-simulation' target='_blank' >First Look at Durability with <strong>3D</strong>EXPERIENCE Works Simulation</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/lp/cloud-enabled-simulation' target='_blank'>Cloud-Enabled Simulation</a>",
    pmodel:"adam-noglasses.glb", ptexture:"adam-noglasses.jpg", rmodel:"room-adam-831b.glb",
    extrasTexture:"extras-adam-831.jpg", 
    rtexture:"room-adam-831.jpg",
    video:["Screen_Adam.mp4"],
    connections:[3,7]
    },
    
    { id: 7, name: "Eric Engineer", 
    avatar: "avatar_Eric.png",
    domain: "Design",  
    role:"<strong>3D</strong>EXPERIENCE SOLIDWORKS",
    summary:"I'm the lead engineer", 
    shortdesc:"I work best when I'm head down and focusing on design. I benefit from tools I can make on my own, with the flexibility to collaborate in a fluid way, because ultimately quality and reliability are my metrics for success.",
    description: "<strong>3D</strong>EXPERIENCE SOLIDWORKS provides me with a comprehensive set of product design capabilities for creating sophisticated 3D models, and application-specific tools. I get the leading SOLIDWORKS CAD tools I’ve come to trust with built-in and secure cloud data management and collaboration my team needs to shorten product development time, reduce cost, and improve quality while pushing the innovation envelope in an increasingly connected world.", 
    resources:"<a href='https://www.solidworks.com/lp/3dexperience-solidworks-offers' target='_blank'>Learn more about <strong>3D</strong>EXPERIENCE SOLIDWORKS</a><br><a href='https://discover.solidworks.com/learn-how-reduce-non-value-added-work-engineering' target='_blank'>Learn How to Reduce Non-Value-Added Work in Engineering</a><br><a href='https://shredder.solidworks.com/' target='_blank'>Learn about other design tools</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/lp/3dexperience-solidworks-offers' target='_blank'><strong>3D</strong>EXPERIENCE SOLIDWORKS</a>",
    pmodel:"eric.glb", 
    ptexture:"eric.jpg", 
    rmodel:"room-eric-92.glb", 
    rtexture:"room-eric-92.jpg",
    video:["Screen_Eric.mp4"],
    connections:[8,3,4,5,6]
    },

    { id: 8, name: "Molly Marketer", 
    avatar: "avatar_Molly.png",
    role:"3D Renderer",
    domain: "Marketing and Sales",  
    summary:"I promote the products we develop", 
    shortdesc:"My role is to make other people's amazing design even more exciting to customers. I work best when collaboration is condensed to what matters to our creative output, while I am not always in the middle of product development, get design data to create content without harassing engineering.",
    description: "3D Render allows me to get the raw materials from product development throughout the process to create amazing marketing materials–before the products are delivered.", 
    resources:"<a href='https://www.solidworks.com/domain/marketing-sales#brick--5085--custom-text-condensed--en' target='_blank'>Learn about all of the offerings for marketing</a><br><a href='http://www.youtube.com/watch?v=kfpcQpLZ0Fg' target='_blank'>Video: Introduction to 3D Render</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/domain/marketing-sales#brick--5085--custom-text-condensed--en' target='_blank'>3D Render</a>",
    pmodel:"molly.glb", ptexture:"molly.jpg", 
    rmodel:"room-molly-92.glb", 
    rtexture:"room-molly-92.jpg",
    video:["Screen-Big_Molly.mp4"],
    connections:[7,1,2,9]
    },
    
    { id: 9, name: "Elaine Executive", 
    avatar: "avatar_Elaine.png",
    domain: "Management/Collaboration", 
    role:"Social Business Analyst", 
    summary:"The buck stops with me", 
    shortdesc:"I may sit at the top of an org chart, but it’s important that I have complete insight into what's going on throughout our business since I make decisions that impact everything.",
    description: "With an integrated portfolio like <strong>3D</strong>EXPERIENCE Works, all of the data from across the orgranization can feed into a single dashboard, providing me with the insight I need to make better decisions faster. ", 
    resources:"<a href='https://www.solidworks.com/product/social-business-analyst' target='_blank'>Social Business Analyst Overview</a><br><a href='https://www.solidworks.com/media/harness-transformative-power-cloud' target='_blank'>Read IDC's take on cloud transformation</a><br><a href='https://www.solidworks.com/media/five-fast-facts-collaborative-business-innovator' target='_blank'>Fast Facts: Collaborative Business Innovator</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/media/five-fast-facts-collaborative-business-innovator' target='_blank'>Collaborative Industry Innovator</a>",
    pmodel:"elaine.glb", 
    ptexture:"elaine.jpg", 
    rmodel:"room-elaine-92b.glb", 
    rtexture:"room-elaine-92.jpg",
    extrasTexture:"extras-elaine-92.jpg",
    video:["Screen-Ticker_Elaine.mp4"],
    connections:[1,2,7,10]
    },
    

    { id: 10, name: "Mitch Manufacturing", 
    avatar: "avatar_Mitch.png",
    domain: "Manufacturing Production",  
    role:"DELMIAworks",
    summary:"I am the top dog of manufacturing operations", 
    shortdesc:"I'm a revenue and profit  guy whomakes  key decisions around investments in people, production tools and technology. To ensure we meet out customers' needs and achieve growth,  it's critical I have total visiblity into the profitability of each of our product lines and resource utilization.",
    description: "Having an ERP system that supports and confirms our business strategy allows us to focus on our core competencies and excel where others might fail. DELMIAworks brought together all the company's functional areas into a single platform (Manufacturing, Finance, Quality). We needed a reliable platform to track costs, inventory and grow our business. It helps drive the company's workflows for manufacturing.", 
    resources:"<a href='https://www.solidworks.com/delmiaworks-manufacturing-erp' target='_blank'>See All Manufacturing/Production Solutions</a><br><a href='https://discover.3ds.com/7-reasons-to-invest-in-erp' target='_blank'>Top Reasons Companies Change ERP Systems</a><br><a href='https://www.youtube.com/watch?v=7GMfqEu36OI' target='_blank'>Video: Leveraging Communication to Expand and Thrive</a>",
    resourcesSummary:"<a href='https://www.solidworks.com/delmiaworks-manufacturing-erp' target='_blank'>DELMIAworks</a>",
    pmodel:"mitch-small-beard.glb", 
    ptexture:"mitch-small-beard.jpg", 
    
   rmodel:"room-mitch-927.glb", 
    rtexture:"room-mitch-927.jpg",
    video:["Screen_Mitch.mp4"],
    connections:[9,0,2]
     },
  
  ],
  skins: [
    {texture:"r5-concrete.jpg", font:"novamono.ttf", thumb:"thumb-concrete.jpg"},
    {texture:"r5-brick.jpg", font:"quickblast.ttf", thumb:"thumb-brick.jpg"},
     {texture:"r5-white.jpg", font:"quickblast.ttf", thumb:"thumb-white.jpg"},
    {texture:"r5-ruby.jpg", font:"novamono.ttf", thumb:"thumb-ruby.jpg"},
    {texture:"r5-black.jpg", font:"quickblast.ttf", thumb:"thumb-black.jpg"},
    {texture:"r5-frosted.jpg", font:"quickblast.ttf", thumb:"thumb-shiny.jpg"},
    {texture:"r5-camo.jpg", font:"novamono.ttf", thumb:"thumb-camo.jpg"},
    {texture:"r5-grass.jpg", font:"novamono.ttf", thumb:"thumb-grass.jpg"},
    {texture:"r5-yellow.jpg", font:"novamono.ttf", thumb:"thumb-yellow.jpg"},
    
  ],
  orbit: {
    lock: true,
    enableDamping: true,
    minPolarAngle: 1,
    maxPolarAngle:  Math.PI - 1.5,
    minAzimuthAngle: 1,
    maxAzimuthAngle: Math.PI - 1,
    minDistance: 8,
    maxDistance: 13
  },
  bisTitles: [
    "Corp", "Co", "Bros", "Inc", "Works", "Tech", "Net"
  ],
  share: {
    title: "Solidworks Build It!",
    description: "See how Solidworks new cloud experience can work for you",
    image:"https://buildit.solidworks.com/share.jpg",
    url:"https://buildit.solidworks.com"
  }
}



